var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "Menu" } }, [
    _c(
      "button",
      {
        staticClass: "hamburger hamburger--collapse",
        attrs: { type: "button" },
        on: {
          click: function($event) {
            return _vm.hamburger()
          }
        }
      },
      [_vm._m(0)]
    ),
    _c("nav", [
      _c("ul", [
        _vm.hasHistoryConsole
          ? _c(
              "li",
              { staticClass: "menu-li" },
              [
                _c("router-link", { attrs: { to: "/historyconsole" } }, [
                  _vm._v("History")
                ])
              ],
              1
            )
          : _vm._e(),
        _vm.hasLiveConsole
          ? _c(
              "li",
              { staticClass: "menu-li" },
              [
                _c("router-link", { attrs: { to: "/console" } }, [
                  _vm._v("Live Console")
                ])
              ],
              1
            )
          : _vm._e(),
        _vm.hasPostDataConsole
          ? _c(
              "li",
              { staticClass: "menu-li" },
              [
                _c("router-link", { attrs: { to: "/postdataconsole" } }, [
                  _vm._v("PostData Console")
                ])
              ],
              1
            )
          : _vm._e(),
        _vm.hasLiveConsole && !_vm.hasPostDataConsole
          ? _c(
              "li",
              { staticClass: "menu-li" },
              [
                _c("router-link", { attrs: { to: "/console" } }, [
                  _vm._v("Console")
                ])
              ],
              1
            )
          : _vm._e(),
        _vm.hasPostDataConsole && !_vm.hasLiveConsole
          ? _c(
              "li",
              { staticClass: "menu-li" },
              [
                _c("router-link", { attrs: { to: "/postdataconsole" } }, [
                  _vm._v("PostData Console")
                ])
              ],
              1
            )
          : _vm._e(),
        _c(
          "li",
          { staticClass: "menu-li" },
          [_c("router-link", { attrs: { to: "/field" } }, [_vm._v("Field")])],
          1
        ),
        _vm.hasPostAnalysis
          ? _c(
              "li",
              { staticClass: "menu-li" },
              [
                _c("router-link", { attrs: { to: "/post" } }, [
                  _vm._v("Set Post")
                ])
              ],
              1
            )
          : _vm._e(),
        _vm.hasLiveAnalysis
          ? _c(
              "li",
              { staticClass: "menu-li" },
              [
                _c("router-link", { attrs: { to: "/live" } }, [
                  _vm._v("Set Live")
                ])
              ],
              1
            )
          : _vm._e(),
        _vm.hasLiveAnalysis
          ? _c(
              "li",
              { staticClass: "menu-li" },
              [
                _c("router-link", { attrs: { to: "/facetoface" } }, [
                  _vm._v("Head To Head")
                ])
              ],
              1
            )
          : _vm._e(),
        _c(
          "li",
          { staticClass: "menu-li" },
          [_c("router-link", { attrs: { to: "/table" } }, [_vm._v("Table")])],
          1
        ),
        _vm.hasTactic
          ? _c(
              "li",
              { staticClass: "menu-li" },
              [
                _c("router-link", { attrs: { to: "/tactic" } }, [
                  _vm._v("Efficiency Indexes")
                ])
              ],
              1
            )
          : _vm._e(),
        _vm.hasTactic
          ? _c(
              "li",
              { staticClass: "menu-li" },
              [
                _c("router-link", { attrs: { to: "/stats" } }, [
                  _vm._v("Stats")
                ])
              ],
              1
            )
          : _vm._e(),
        _c(
          "li",
          { staticClass: "menu-li" },
          [
            _c("router-link", { attrs: { to: "/perfidx" } }, [
              _vm._v("5 min Physical Eff")
            ])
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "menu-li" },
          [_c("router-link", { attrs: { to: "/logout" } }, [_vm._v("Logout")])],
          1
        )
      ])
    ]),
    _vm.appStyle == "stats"
      ? _c("img", {
          staticClass: "menu",
          attrs: {
            src: require("@/assets/images/general/menu_stats.svg"),
            alt: ""
          }
        })
      : _c("img", {
          staticClass: "menu",
          attrs: { src: require("@/assets/images/general/menu.svg"), alt: "" }
        })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "hamburger-box" }, [
      _c("span", { staticClass: "hamburger-inner" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }