<template>
  <div id="Menu">
    <button
      class="hamburger hamburger--collapse"
      type="button"
      @click="hamburger()"
    >
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
    <nav>
      <ul>
        <li class="menu-li" v-if="hasHistoryConsole">
          <router-link to="/historyconsole">History</router-link>
        </li>
        <li class="menu-li" v-if="hasLiveConsole">
          <router-link to="/console">Live Console</router-link>
        </li>
        <li class="menu-li" v-if="hasPostDataConsole">
          <router-link to="/postdataconsole">PostData Console</router-link>
        </li>
        <li class="menu-li" v-if="hasLiveConsole && !hasPostDataConsole">
          <router-link to="/console">Console</router-link>
        </li>
        <li class="menu-li" v-if="hasPostDataConsole && !hasLiveConsole">
          <router-link to="/postdataconsole">PostData Console</router-link>
        </li>
        <li class="menu-li">
          <router-link to="/field">Field</router-link>
        </li>
        <li class="menu-li" v-if="hasPostAnalysis">
          <router-link to="/post">Set Post</router-link>
        </li>
        <li class="menu-li" v-if="hasLiveAnalysis">
          <router-link to="/live">Set Live</router-link>
        </li>
        <li class="menu-li" v-if="hasLiveAnalysis">
          <router-link to="/facetoface">Head To Head</router-link>
        </li>
        <li class="menu-li">
          <router-link to="/table">Table</router-link>
        </li>
        <li class="menu-li" v-if="hasTactic">
          <router-link to="/tactic">Efficiency Indexes</router-link>
        </li>
        <li class="menu-li" v-if="hasTactic">
          <router-link to="/stats">Stats</router-link>
        </li>
        <li class="menu-li">
          <router-link to="/perfidx">5 min Physical Eff</router-link>
        </li>
        <li class="menu-li">
          <router-link to="/logout">Logout</router-link>
        </li>
      </ul>
    </nav>
    <img
      v-if="appStyle == 'stats'"
      class="menu"
      src="~@/assets/images/general/menu_stats.svg"
      alt
    />
    <img v-else class="menu" src="~@/assets/images/general/menu.svg" alt />
    <!-- <div id="circleSvg" ref="circleSvg">
      <svg xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern
            id="image"
            x="0%"
            y="0%"
            height="100%"
            width="100%"
            viewBox="0 0 512 512"
          >
            <image
              x="0%"
              y="0%"
              width="1000"
              height="600"
              xlink:href="~@/assets/images/general/sfondo.jpg"
            />
          </pattern>
        </defs>
        <circle
          ref="circle"
          cx="50%"
          cy="50%"
          r="0"
          fill="#303030"
          stroke-width="2"
          stroke="#fdf800"
        />
      </svg>
    </div> -->
  </div>
</template>

<script>
import "../../../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";
import { TweenMax, TimelineMax, TweenLite, Power2 } from "gsap";
export default {
  data() {
    return {
      r: 250,
      dur: 0.75,
    };
  },
  computed: {
    hasPostAnalysis() {
      return this.$store.state.user.user.permissions.includes("POST_ANALYSIS");
    },
    hasLiveConsole() {
      return this.$store.state.user.user.permissions.includes("LIVE_CONSOLE");
    },
    hasTactic() {
      return this.$store.state.user.user.permissions.includes("TACTIC");
    },
    hasPostDataConsole() {
      return this.$store.state.user.user.permissions.includes(
        "POSTDATA_CONSOLE"
      );
    },
    hasLiveAnalysis() {
      return this.$store.state.user.user.permissions.includes("LIVE_ANALYSIS");
    },
    hasHistoryConsole() {
      return this.$store.state.user.user.permissions.includes("HISTORY_CONSOLE");
    },
    appStyle() {
      return this.$store.state.appStyle;
    },
  },
  methods: {
    hamburger(e) {
      TweenLite.defaultEase = Power2.easeInOut;
      // const circleSvg = this.$refs["circleSvg"];
      // const circle = this.$refs["circle"];
      const menuli = document.getElementsByClassName("menu-li");
      const nav = document.getElementsByTagName("nav")[0];
      // TweenMax.set(circle, { x: -100, y: 80 });
      const hamburger = document.getElementsByClassName("hamburger")[0];
      if (hamburger.classList.contains("is-active")) {
        hamburger.classList.remove("is-active");
        // this.closeMenu(circle, circleSvg);
        TweenMax.to(nav, 0.1, { autoAlpha: 0, delay: 0.1 });
        TweenMax.staggerTo(menuli, 0.1, { x: -10, opacity: 0 }, 0.1);
      } else {
        hamburger.classList.add("is-active");
        TweenMax.staggerTo(menuli, 0.1, { x: 0, opacity: 1, delay: 0.1 }, 0.1);
        TweenMax.to(nav, 0.1, { autoAlpha: 1, delay: 0 });
        // this.openMenu(circle, circleSvg);
      }
    },
    openMenu(circle) {
      var tl = new TimelineMax({});
      tl.to(circleSvg, 0.1, { autoAlpha: 1 }).to(circle, this.dur, {
        attr: { r: this.r },
      });
    },
    closeMenu(circle) {
      var tl = new TimelineMax({ delay: 0.3 });
      tl.to(circle, 0.3, {
        attr: { r: 0 },
      }).to(circleSvg, 0.1, { autoAlpha: 0 });
    },
  },
  mounted() {
    const circle = this.$refs["circle"];
    const menuli = document.getElementsByClassName("menu-li");
    const nav = document.getElementsByTagName("nav")[0];
    TweenMax.set(menuli, { x: -20 });
    TweenMax.set(circle, { x: -610, y: 436 });
  },
};
</script>

<style lang="scss" scoped>
nav {
  position: fixed;
  bottom: 150px;
  left: 20px;
  z-index: 1200;
  background-color: var(--background-color);
  background-image: var(--background-image);
  padding-right: 70px;
  visibility: hidden;
  border: var(--border);
  ul {
    list-style: none;
    font-weight: bold;
    li {
      margin: 24px 0;
      padding-left: 8px;
      opacity: 0;
      text-align: left;
      display: block;
      a {
        text-align: left;
        color: var(--color);
        text-decoration: none;
        &:hover {
          background: #cccccc;
          color: #333333;
        }
      }
      &:hover ul {
        display: block;
      }
    }
    a {
      width: 150px;
    }
    ul {
      position: absolute;
      left: 200;
      top: 100%;
      display: none;
      padding: 0px;
      margin: 0px;
      a {
        width: 150px;
      }
      li {
        display: block;
        margin: 0px;
        padding-left: 58px;
      }
    }
  }
}
.v-application {
  color: var(--color);
  text-decoration: none;
}
#circleSvg {
  // background-image: url("~@/assets/images/general/sfondo.jpg");
  position: fixed;
  bottom: 0;
  left: 0;
  width: 350px;
  height: 350px;
  visibility: hidden;
  z-index: 1000;
  svg {
    position: fixed;
    bottom: -12px;
    left: 0;
    width: 350px;
    height: 350px;
    z-index: 1000;
  }
}
$hamburger-layer-color: var(--color) !default;
$hamburger-layer-border-radius: 0px !default;
$hamburger-layer-width: 30px !default;
$hamburger-padding-x: 0px !default;
$hamburger-padding-y: 0px !default;
$hamburger-layer-height: 3px !default;
.hamburger {
  position: fixed;
  left: 39px;
  bottom: 46px;
  z-index: 1100;
  &:focus {
    outline: none;
    opacity: 1;
  }
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  background-color: var(--color) !important;
  border-radius: 0px;
}
.hamburger-inner::before {
  top: -10px;
}
.hamburger-inner::after {
  bottom: -20px;
}
.menu {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1050;
}
</style>
